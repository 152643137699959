import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import CssTextField from "../components/CssTextField";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axiosClient from "../api-client";
import { useMobile } from "../components/MobileWrapper";
import PasswordField from "../components/PasswordField";
import TermsAndRegulationsDialog from "../MyAccount/TermsAndRegulationsDialog";

const InnerContainer = styled("div")`
  margin: auto;
  width: 500px;
`;

const RegistrationPage = (props) => {
  const { t } = useTranslation();
  const { t: _t } = useTranslation(undefined, { keyPrefix: "DjangoErrors" });
  const navigate = useNavigate();
  const { mobileWidthSx } = useMobile();

  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassowrd] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const validateEmail = (value) => {
    if (!value) {
      setErrors({ ...errors, email: "Required." });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      setErrors({ ...errors, email: "invalid-email" });
    } else setErrors({ ...errors, email: null });
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    validateEmail(value);
    setEmail(value);
  };

  const handlePasswordChange = (e) => setPassowrd(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const { mutate, isLoading } = useMutation("user-registration", () => {
    const data = {
      email,
      password,
      confirm_password: confirmPassword,
    };
    axiosClient
      .post(`/api/v1/auth/create-account/`, data)
      .then(() => {
        toast(t("Register.success"));
        setTimeout(() => navigate(`/activation-required`), 1000);
      })
      .catch((err) => setErrors(err.response.data));
  });

  const onKeyDown = (e) => (e.key === "Enter" ? mutate() : null);

  return (
    <InnerContainer sx={mobileWidthSx}>
      <Typography variant={"h4"} component={"div"}>
        {t("Register.createAccount")}
      </Typography>
      <br />
      <CssTextField
        focusColor="black"
        fullWidth
        label={t("Register.email")}
        placeholder={t("Register.email")}
        value={email}
        onChange={handleEmailChange}
        type="email"
        error={!!errors.username || !!errors.email}
        helperText={_t(errors.username || errors.email, "")}
        onKeyDown={onKeyDown}
      />
      <br />
      <br />
      <PasswordField
        component={CssTextField}
        focusColor="black"
        fullWidth
        label={t("Register.password")}
        placeholder={t("Register.password")}
        value={password}
        error={!!errors.password}
        helperText={_t(errors.password, "")}
        onChange={handlePasswordChange}
        onKeyDown={onKeyDown}
      />
      <br />
      <br />
      <PasswordField
        component={CssTextField}
        focusColor="black"
        fullWidth
        label={t("Register.confirmPassword")}
        placeholder={t("Register.confirmPassword")}
        value={confirmPassword}
        type="password"
        error={!!errors.confirmPassword}
        helperText={_t(errors.confirmPassword, "")}
        onChange={handleConfirmPasswordChange}
        onKeyDown={onKeyDown}
      />
      <br />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onClick={() => setChecked((prevState) => !prevState)}
          />
        }
        label={
          <Typography>
            {t("MyAccount.termsAndRegulations.message")}{" "}
            <Typography
              component="span"
              color="primary.main"
              onClick={() => setTermsModalOpen(true)}
            >
              {t("MyAccount.termsAndRegulations.title")}.
            </Typography>
          </Typography>
        }
      />
      <br />
      <br />
      <Button
        fullWidth
        variant="contained"
        disabled={
          isLoading ||
          !checked ||
          !email ||
          !password ||
          !confirmPassword
        }
        type="submit"
        onClick={mutate}
      >
        {t("Register.createAccount")}
      </Button>

      <Button
        fullWidth
        variant="outlined"
        disabled={isLoading}
        onClick={() => navigate("/")}
        sx={{ mt: "20px" }}
      >
        {t("Register.back")}
      </Button>
      <TermsAndRegulationsDialog
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
    </InnerContainer>
  );
};

export default RegistrationPage;
