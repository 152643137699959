import { useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";

import AppToolbar from "../AppToolbar";
import GiftTable from "./GiftTable";
import MobileWrapper from "../components/MobileWrapper";
import GiftCards from "./GiftCards";
import OptionalAuthorization from "./OptionalAuthorization";
import StyledFlex from "../components/StyledFlex";
import { Box, FormControlLabel, Switch } from "@mui/material";
import AllowGiftProposition from "./AllowGiftProposition";
import { useInviteeList } from "../hooks";
import { useTranslation } from "react-i18next";

const StyledName = styled("h2")``;

const InviteeList = (props) => {
  const { t } = useTranslation();
  const { inviteLink } = useParams();
  const { data } = useInviteeList(inviteLink);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(true);

  return (
    <OptionalAuthorization>
      <AppToolbar />
      <div style={{ margin: "80px 20px 20px 20px" }}>
        <StyledFlex>
          <Box>
            <StyledName>{data?.name}</StyledName>
          </Box>
          {/** disabling toggle button in mobile resolution */}
        </StyledFlex>
        <MobileWrapper
          component={
            <Box sx={{m: "0px -20px 0px -30px"}}>
              <AllowGiftProposition />
              <br/>
              <FormControlLabel
                sx={{ ml: "20px" }}
                control={
                  <Switch
                    color="success"
                    checked={showOnlyAvailable}
                    onChange={() =>
                      setShowOnlyAvailable((prevState) => !prevState)
                    }
                  />
                }
                label={t("InviteeList.showReserved")}
              />
            </Box>
          }
        >
          <StyledFlex sx={{ justifyContent: "flex-start" }}>
            <AllowGiftProposition />
            <FormControlLabel
              sx={{ ml: "20px" }}
              control={
                <Switch
                  color="success"
                  value={setShowOnlyAvailable}
                  onChange={() =>
                    setShowOnlyAvailable((prevState) => !prevState)
                  }
                />
              }
              label={t("InviteeList.showReserved")}
            />
          </StyledFlex>
        </MobileWrapper>
        <br />

        {/** Card View only for mobile resolution */}
        <MobileWrapper
          width={800}
          component={<GiftCards showOnlyAvailable={showOnlyAvailable} />}
        >
          <GiftTable showOnlyAvailable={showOnlyAvailable} />
        </MobileWrapper>
      </div>
    </OptionalAuthorization>
  );
};

export default InviteeList;
